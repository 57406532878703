

import React, { useState } from "react";
import AgentComponent from './AgentComponent'
import AgentPerfermanceComponent from './AgentPerfermanceComponent'
import { Button } from '@fluentui/react-northstar'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import translate from "../i18n/translate";

const AgentsComponent = (props) => {
    const [showAgentDiv, setShowAgentDiv] = useState("")
    return (
        <div className="agents-mean-div" >
            <div style={{ display: 'flex' }}>
                <h4>{translate('AGENTS')}</h4>
                <Button icon={
                    showAgentDiv === "" ?
                        <BsChevronDown onClick={() => { setShowAgentDiv('hide') }} />
                        :
                        <BsChevronUp onClick={() => { setShowAgentDiv('') }} />
                } text iconOnly title="Create" />
            </div>

            <div className="row" id={showAgentDiv}>

                {
                    props.agentMonitorMode && (props.agentMonitorMode === "PERMANENT_IN_DASHBORD" || props.agentMonitorMode === "SUPERVISE_PSTN_ONLY") && props.signalRConnexion !== null ?
                        <>
                            {
                                Object.keys(props.allAgentDetails).map((agentId, i) => {
                                    return (
                                        < AgentPerfermanceComponent
                                            index={i}
                                            key={agentId}
                                            agentDetail={props.allAgentDetails[agentId]}
                                            signalRConnexion={props.signalRConnexion}
                                            agentMonitorMode={props.agentMonitorMode}
                                        />

                                    )
                                })
                            }
                        </>
                        :
                        <>

                            {
                                Object.keys(props.allAgentDetails).map((agentId) => {
                                    return (
                                        < AgentComponent
                                            key={agentId}
                                            agentDetail={props.allAgentDetails[agentId]}
                                            signalRConnexion={props.signalRConnexion}
                                        />
                                    )
                                })
                            }
                        </>

                }
            </div>


        </div>
    )
}
export default AgentsComponent