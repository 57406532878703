const initialState = {
  MessageOfQueus: [],
  QueueDataFromApi: []
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case "QUEUE FROM SIGNALR":
      let QueuId = action.MessagesFromApiOfQueu.fromAPI.RowKey;
      let newMessagOfQueus = { ...state.MessageOfQueus };
      newMessagOfQueus = { ...newMessagOfQueus, [QueuId]: action.MessagesFromApiOfQueu };
      return { ...state, MessageOfQueus: newMessagOfQueus }

    case "QUEUE FROM API":
      let Id = action.queue.RowKey;
      let newQueues = { ...state.QueueDataFromApi };
      newQueues = { ...newQueues, [Id]: action.queue };
      return { ...state, QueueDataFromApi: newQueues }

    default:
      return;
  }
  return state;
};

export default reducer;
