import React, { useState, useEffect } from "react";
import { getPersonPhoto } from './Services'
import { authContext } from "../auth";
import { Persona, PersonaSize, PersonaPresence } from "office-ui-fabric-react/lib/Persona";
import { VscCallOutgoing, VscCallIncoming } from 'react-icons/vsc'
import Person from './Person'
import Timer from './timer/Timer'
import translate from "../i18n/translate";
import axios from "axios";
import { getAccessToken } from './Services';
const AgentComponent = (props) => {
    const [agentConnectedCalls, setAgentConnectedCalls] = useState()
    const [agentPhoto, setAgentPhoto] = useState()
    const Size = PersonaSize.size28;
    const personaStyles = { root: { margin: "3px 0 3px 0" } };
    const AGENT_CONNECTED_CALLS_WEBSERVICE_PATH = "https://provisioning.heedify.io/GetAgentConnectedCalls/"
    useEffect(() => {
        if (props.agentDetail && props.signalRConnexion !== null) {
            getAccessToken(authContext).then((token) => {
                let url = AGENT_CONNECTED_CALLS_WEBSERVICE_PATH + authContext._user.profile.tid + "/" + props.agentDetail.id + "?token=" + token
                axios.post(url).then((result) => {
                    if (result && result.data && result.data !== '' && result.data.connectedCalls && Object.keys(result.data.connectedCalls).length > 0) {
                        setAgentConnectedCalls(result.data.connectedCalls)
                    } else {
                        setAgentConnectedCalls({})
                    }
                }).then(() => {
                    const onNotifReceived = async (result) => {
                        setAgentConnectedCalls(result.connectedCalls)
                    };
                    props.signalRConnexion.on("agentCalls" + props.agentDetail.id, onNotifReceived);

                })
            })
        }
    }, [props.agentDetail, props.signalRConnexion])
    useEffect(() => {
        getPersonPhoto(authContext, props.agentDetail.id).then((photo) => {
            setAgentPhoto(URL.createObjectURL(photo))
        })
    }, [])
    return (
        <>
            {
                agentConnectedCalls && Object.keys(agentConnectedCalls).length > 0 ?
                    <div className="col-6" >
                        {

                            Object.values(agentConnectedCalls).map((call) => {
                                return (
                                    <div className="agent-mean-div" key={call.id}>
                                        <div className="agent-person-div">
                                            {
                                                agentPhoto ?
                                                    <Persona
                                                        {...{
                                                            imageUrl: agentPhoto,
                                                            text: props.agentDetail.displayName,
                                                            showSecondaryText: false,
                                                            // imageShouldStartVisible: true,
                                                            imageShouldFadeIn: true,
                                                        }}
                                                        size={Size}
                                                        styles={personaStyles}
                                                    />
                                                    :
                                                    <Persona
                                                        {...{
                                                            text: props.agentDetail.displayName,
                                                            showSecondaryText: false,
                                                            // imageShouldStartVisible: true,
                                                            imageShouldFadeIn: true,
                                                        }}
                                                        size={Size}
                                                        styles={personaStyles}
                                                    />

                                            }
                                        </div>
                                        <span className="agent-direction-call" >{call.direction && call.direction === "OUT" ? <VscCallOutgoing /> : <VscCallIncoming />}</span>
                                        <div className="agent-caller-div">
                                            {
                                                call.callerType && call.callerType === "user" ?

                                                    <Person
                                                        personId={call.callerId}
                                                    />

                                                    :
                                                    call.callerId
                                            }
                                        </div >
                                        <div className="agent-origin-div" >
                                            <span style={{ width: '80%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{call.origin && call.origin === "DIRECT" ? translate("DIRECT") : call.flowName}</span>
                                            <span style={{ width: '20%' }}><Timer className="timer" startTime={call.connectedAt} isCall={false} limitTimeStatus={null} /></span>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    null
            }

        </>

    )
}
export default AgentComponent