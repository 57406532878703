import { LOCALES } from '../locales';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    [LOCALES.ENGLISH]: {
        AVRAGE_WAIT_TIME: 'Average queuing time',
        QUEUED_CALLS: "Waiting",
        EXPECTED_WAIT_TIME: 'Alerting agent',
        QUEUE: 'Queue',
        HEADER_TITLE: 'Heedify Real Time Dashboard',
        AGENTS: 'Agents',
        DIRECT: "Direct",
        TODAY_CALLS: "Today calls",
        MISSED_CALLS: "Missed calls",
        CONNECTED_CALLS: "Connected calls",
        TASKS_COMPLETION_RATE: "Tasks completed",
        CALL_DURATION: "Calls duration",
        CHAT:"Chat"

    }
}