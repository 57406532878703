import React, { Component, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './components/front/navBar';
import Dashboard from './containers/myDashboard';
import { I18nProvider, LOCALES } from './i18n';
import translate from './i18n/translate';
import detectBrowserLanguage from 'detect-browser-language';

function App() {


  let langueOfBrowser = detectBrowserLanguage();
  let localeLangue = LOCALES.ENGLISH;
  if (langueOfBrowser === "fr-FR") {
    localeLangue = LOCALES.FRENCH;
  }

  return (
    <I18nProvider locale={localeLangue}>
      <div className="App row  justify-content-center">
        <NavBar id="navBar" />
        <Dashboard />
      </div>
    </I18nProvider>
  );

}


export default App;