import React, { useEffect, useState } from "react";
import moment from "moment";
import { Label } from "@fluentui/react-northstar";
const Timer = ({ startTime, isCall, limitTimeStatus }) => {
    const [time, setTime] = useState(() => {
        let t = Date.now() - startTime * 1000;
        return t;
    });
    const [timeStyle, setTimeStyle] = useState((style) => style);

    const inTimeStyle = {
        color: "#2f2f2f",
    };

    const islongStyle = {
        color: "white",
        backgroundColor: "#CC4A31",
    };
    const isCallStyle = {
        color: "#585A96",
        backgroundColor: "rgb(226, 226, 246)",
        fontSize: "12px!important",
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(() => {
                let t = Date.now() - startTime * 1000;
                return t;
            });
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [startTime]);
    useEffect(() => {
        const timeStatus = time;
        limitTimeStatus = limitTimeStatus * 1000;
        if (isCall) {
            setTimeStyle(isCallStyle);
        } else if (
            limitTimeStatus &&
            limitTimeStatus !== "" &&
            limitTimeStatus !== null &&
            timeStatus > limitTimeStatus
        ) {
            setTimeStyle(islongStyle);
        } else {
            setTimeStyle(inTimeStyle);
        }
    }, [time]);
    let timeToDisplay = "-:-";
    if (startTime > 0) {
        timeToDisplay = moment.utc(moment(time)).format("mm:ss");
    }
    return (
        <Label
            className="timer ms-fontWeight-regular"
            style={timeStyle}
            content={timeToDisplay}
            iconPosition="start"
        />
    );
};

export default Timer;
