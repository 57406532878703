import React, { useState, useEffect } from "react";
import translate from "../i18n/translate";
const QueueComponent = (props) => {
    const [queueLiveData, setQueueLiveData] = useState();
    function convertTime(sec) {
        var hours = Math.floor(sec / 3600);
        (hours >= 1) ? sec = sec - (hours * 3600) : hours = '00';
        var min = Math.floor(sec / 60);
        (min >= 1) ? sec = sec - (min * 60) : min = '00';
        (sec < 1) ? sec = '00' : void 0;
        (min.toString().length === 1) ? min = '0' + min : void 0;
        (sec.toString().length === 1) ? sec = '0' + sec : void 0;
        // to show hours use (return hours + ':' + min + ':' + sec;)
        return min + ':' + sec;
    }
    useEffect(() => {
        if (props.queue && props.signalRConnexion !== null) {
            const onNotifReceived = async (result) => {
                setQueueLiveData(result);
            };
            props.signalRConnexion.on(props.queue.RowKey, onNotifReceived);

        }
    }, [props.queue, props.signalRConnexion])
    return (
        <div className="col-6" key={props.queue.RowKey}>
            <div className="queue-name">{props.queue.queueName} </div>
            <div className='queues-small-elements-div'>
                <div className="realTimeElement">
                    <p className="queue-item-title">{translate('QUEUE')}</p>
                    <p className="queue-item-value">{queueLiveData && queueLiveData.calls ?
                        Object.keys(queueLiveData.calls).length
                        :
                        props.queue.calls ?
                            Object.keys(props.queue.calls).length
                            :
                            0
                    }</p>
                </div>
                <div className="realTimeElement">
                    <p className="queue-item-title">{translate('CHAT')}</p>
                    <p className="queue-item-value">{queueLiveData && queueLiveData.tasks ?
                        Object.keys(queueLiveData.tasks).length
                        :
                        props.queue.tasks ?
                            Object.keys(props.queue.tasks).length
                            :
                            0
                    }</p>
                </div>
                <div className="realTimeElement " >
                    <p className="queue-item-title">{translate('QUEUED_CALLS')}</p>
                    <p className="queue-item-small-value"> {
                        queueLiveData && queueLiveData.calls && Object.keys(queueLiveData.calls).length > 0 ?
                        Object.values(queueLiveData.calls).filter(element => {
                                if (element.state !== "WAITING_AGENT") {
                                    return true;
                                }
                                return false;
                            }).length
                            :
                            0
                    }</p>
                </div>
                <div className="realTimeElement " >
                    <p className="queue-item-title">{translate('EXPECTED_WAIT_TIME')}</p>
                    <p className="queue-item-small-value">{queueLiveData && queueLiveData.calls && Object.keys(queueLiveData.calls).length > 0 ?
                        Object.values(queueLiveData.calls).filter(element => {
                            if (element.state === "WAITING_AGENT") {
                                return true;
                            }
                            return false;
                        }).length
                        :
                        0
                    }</p>
                </div>
                <div className="realTimeElement">
                    <p className="queue-item-title">{translate('AVRAGE_WAIT_TIME')}</p>
                    <p className="queue-item-value">{queueLiveData && queueLiveData.averageQueueingTime ? convertTime(queueLiveData.averageQueueingTime) : 0}</p>
                </div>
                <div className="realTimeElement">
                    <p className="queue-item-title">SLA({queueLiveData && queueLiveData.sla ? convertTime(queueLiveData.sla) : convertTime(props.queue.sla)})</p>
                    <p className="">{
                        queueLiveData && queueLiveData.sla ?
                            <div>
                                {
                                    ((queueLiveData.slaReached / queueLiveData.todayQueuedCalls) * 100) > props.queue.slaThreshold ?
                                        <span className="green-SAL-div">{((queueLiveData.slaReached / queueLiveData.todayQueuedCalls) * 100).toFixed(1)} %</span>
                                        :
                                        <span className="red-SAL-div">{((queueLiveData.slaReached / queueLiveData.todayQueuedCalls) * 100).toFixed(1)} %</span>

                                }
                            </div>
                            :
                            null
                    }</p>
                </div>
            </div>

        </div>
    )
}
export default QueueComponent