import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

export const config = {
  //clientId: "2eaa21a7-3855-4be3-9bb3-926ce86da20c",
  clientId: "f3ad2154-7e10-4730-a055-8852ced42d47",
  // redirectUri must be in the list of redirect URLs for the Azure AD app
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(config);
