import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reducer from "./store/reducer";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { authContext } from "./auth";
import { Providers, SimpleProvider, ProviderState } from "@microsoft/mgt";
import {
  Provider as TeamProvider,
  teamsTheme,
} from "@fluentui/react-northstar";

function getAccessToken(scopes) {
  return new Promise((resolve, reject) => {
    authContext.acquireToken(
      "https://graph.microsoft.com",
      function (errDesc, token, err, tokenType) {
        if (token) {
          Providers.globalProvider.setState(ProviderState.SignedIn);
          resolve(token);
        }
      }
    );
  });
}

function login() {
  console.log("Login user in");
  authContext.handleWindowCallback();
  if (authContext.login()) {
    authContext.login().then(() => {
      console.log("loged in ");
      Providers.globalProvider.setState(ProviderState.SignedIn);
    });
  }

}

function logout() {
  authContext.logOut();
}

let provider = new SimpleProvider(getAccessToken, login, logout);
Providers.globalProvider = provider;
authContext.handleWindowCallback();
let user = authContext.getCachedUser();
if (user) {
  Providers.globalProvider.setState(ProviderState.SignedIn);
} else {
  login()
}
const store = createStore(reducer);

ReactDOM.render(
  <React.StrictMode>
    <TeamProvider theme={teamsTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </TeamProvider>
  </React.StrictMode>,
  document.getElementById("heedifyRoot")
);
