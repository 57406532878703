import React from "react";
import translate from "../i18n/translate";
const AgentListHeader = (props) => {
    return (
        <>
            <div className="agent-mean-div" style={{ height: '45%', marginBottom: '3px' }} >
                <div className="agent-person-div" style={{ textAlign: 'center' }}>
                    Agent
                </div>
                <div className="agent-perfermance-item" ><div > {translate('TODAY_CALLS')}</div></div>
                <div className="agent-perfermance-item" > <div>{translate('MISSED_CALLS')}</div></div>
                <div className="agent-perfermance-item" > <div>{translate('CALL_DURATION')}</div></div>
                <div className="agent-perfermance-item" ><div>{translate('CONNECTED_CALLS')}</div></div>
                <div className="agent-perfermance-item" ><div>{translate('TASKS_COMPLETION_RATE')}</div></div>
            </div>
        </>

    )
}
export default AgentListHeader