/* eslint-disable no-const-assign */
import React, { Component, useState, useEffect } from "react";
import Queu from "./front/queu";
import translate from "../i18n/translate";
import axios from "axios";
import { authContext } from "../auth";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getAccessToken, getQueues, getAgentGroups, getMyScops, getCallFlows } from './Services';
import QueuesComponent from './QueuesComponent'
import AgentsComponent from './AgentsComponent'
import logo from "../HeedifyLogo_H.png";
authContext.handleWindowCallback();
const Dashboard = (props) => {
  const tenantId = authContext._user.profile.tid;
  const userId = authContext._user.profile.oid
  const [allQueues, setAllQueues] = useState({})
  const [allCallFlows, setAllCallFlows] = useState({})
  const [allGroups, setGroups] = useState({});
  const [groupsAgents, setGroupsAgents] = useState({});
  const [allAgentDetails, setAllAgentDetails] = useState({});
  const [signalRConnexion, setSignalRConnexion] = useState(null);
  const [agentMonitorMode, setAgentMonitorMode] = useState("DEFAULT")
  const creatSignalRConnexion = () => {
    const urlRoot = "https://heedifysignalr.azurewebsites.net/api";
    const startSignalRConnection = (connection) =>
      connection
        .start({
          transport: [
            "webSockets",
            "longPolling",
            "foreverFrame",
            "serverSentEvents",
          ],
        })
        .then(() => {
          setSignalRConnexion(connection)
        }).catch((err) => console.error("SignalR Connection Error: ", err));
    const connection = new HubConnectionBuilder()
      .withUrl(urlRoot)
      .configureLogging(LogLevel.Warning)
      .build();
    connection.onclose(() =>
      setTimeout(startSignalRConnection(connection), 5000)
    );
    startSignalRConnection(connection);
  };
  useEffect(() => {
    creatSignalRConnexion();
    getAccessToken(authContext).then((token) => {
      getMyScops(tenantId, token).then((scopes) => {
        console.log('scopes===>', scopes)
        let getQueuesPromises = [];
        let callFlowsPromises = [];
        let groupsPromises = [];

        for (const scope of Object.keys(scopes)) {
          getQueuesPromises.push(getQueues(tenantId, scope, userId))
          callFlowsPromises.push(getCallFlows(tenantId, scope, userId))
          groupsPromises.push(getAgentGroups(tenantId, scope, userId))
        }
        Promise.all(getQueuesPromises).then((results) => {
          let allQueues = {}
          for (const result of results) {
            if (Object.keys(result).length > 0) {
              for (const queue of Object.keys(result)) {
                allQueues[queue] = result[queue];
              }
            }
          }
          setAllQueues(allQueues)

        });
        Promise.all(callFlowsPromises).then((results) => {
          let allCallFlows = {}
          for (const result of results) {
            if (Object.keys(result).length > 0) {
              for (const callFlow of Object.keys(result)) {
                allCallFlows[callFlow] = result[callFlow]
              }
            }
          }
          setAllCallFlows(allCallFlows)
        });
        Promise.all(groupsPromises).then((results) => {
          let allGroups = {}
          let agents = {}
          let agentDetails = {}
          for (const result of results) {
            if (result.length > 0) {
              for (const group of result) {
                allGroups[group.id] = group
              }
            }

          }
          setGroups(allGroups)
          for (const groupId of Object.keys(allGroups)) {
            if (allGroups[groupId].supervisionMode && (allGroups[groupId].supervisionMode === "PERMANENT_IN_DASHBORD" || allGroups[groupId].supervisionMode === "SUPERVISE_PSTN_ONLY")) {
              if (allGroups[groupId].supervisionMode === "SUPERVISE_PSTN_ONLY") {
                setAgentMonitorMode("SUPERVISE_PSTN_ONLY")
              }
              if (allGroups[groupId].supervisionMode === "PERMANENT_IN_DASHBORD" && agentMonitorMode !== "SUPERVISE_PSTN_ONLY") {
                setAgentMonitorMode("PERMANENT_IN_DASHBORD")
              }
            }
            for (const [key, value] of Object.entries(allGroups[groupId].agentDetails)) {
              agentDetails[key] = {
                id: key,
                displayName: value.displayName,
                email: value.email
              }
            }
            for (const agentId of allGroups[groupId].agentsIds) {
              agents[groupId] = allGroups[groupId].agentDetails;

            }
          }
          setGroupsAgents(agents)
          setAllAgentDetails(agentDetails)
        });
      })
    })
  }, [])
  return (
    <div className="ConnexionToBackEnd" >
      <div>
        <img
          className='Heedify-header-logo'
          alt="Heedify"
          src={logo}
        ></img>
        <h2 className="title-dashboard">
          {translate("HEADER_TITLE")}
        </h2>
      </div>
      <QueuesComponent
        allQueues={allQueues}
        signalRConnexion={signalRConnexion}
      />
      <AgentsComponent
        allAgentDetails={allAgentDetails}
        signalRConnexion={signalRConnexion}
        agentMonitorMode={agentMonitorMode}
      />
    </div>
  );
}
export default Dashboard

