import React, { useState, useEffect } from "react";
import QueueComponent from './QueueComponent'
const QueuesComponent = (props) => {
    return (
        <div id='queues-mean-div'>
            <div className="row">
                {
                    Object.keys(props.allQueues).map((queueId) => {
                        return (
                            < QueueComponent
                                key={queueId}
                                queue={props.allQueues[queueId]}
                                signalRConnexion={props.signalRConnexion}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}
export default QueuesComponent