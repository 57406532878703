import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Login, Get } from "@microsoft/mgt-react";
class NavBar extends Component {

    render() {
        const handleSelect = (eventKey) => alert(`selected ${eventKey}`);
        return (

            <Nav variant="pills" activeKey="1" onSelect={handleSelect}>
                <Login />
                <Get resource="users/me"></Get>
            </Nav>
        )
    }
}
export default NavBar;
