
//création et export des fonction q'on vas les utuliser dans le containeur
export const setQueuesData = (res) => ({
  type: "QUEUE FROM SIGNALR",
  MessagesFromApiOfQueu: res

});
export const setPresence = (res) => ({
  type: "Presence"
});
export const setQueuesFromApi = (queue) => ({
  type: 'QUEUE FROM API',
  queue
})