import React, { useState, useEffect } from "react";
import { getPersonPhoto, getUserDetails } from './Services'
import { authContext } from "../auth";
import { Persona, PersonaSize, PersonaPresence } from "office-ui-fabric-react/lib/Persona";
const AgentComponent = (props) => {
    const Size = PersonaSize.size28;
    const personaStyles = { root: { margin: "3px 0 3px 0" } };
    const [personDetails, setPersonDetails] = useState()
    useEffect(() => {
        getUserDetails(authContext, props.personId).then((details) => {
            setPersonDetails(details)
        })
    }, [])
    return (
        <>
            {
                personDetails ?
                    <Persona
                        {...{
                            imageUrl: null,
                            text: personDetails.displayName,
                            showSecondaryText: false,
                            // imageShouldStartVisible: true,
                            imageShouldFadeIn: true,
                        }}
                        size={Size}
                        styles={personaStyles}
                    />
                    :
                    null
            }
        </>
    )
}
export default AgentComponent