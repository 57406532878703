import React, { Component } from 'react';
import { CacheService } from '@microsoft/mgt';
import '@microsoft/mgt';

class Person extends Component {


    render() {

        return (
            <>
                <mgt-msal-provider
                    client-id="234d57fe-8b3f-43d4-baa4-c80f1f593506"
                    scopes="user.read,people.read,user.readbasic.all,contacts.read,calendars.read,presence.read"
                    depends-on="mgt-teams-provider"
                ></mgt-msal-provider>
            </>
        );
    }
}

export default Person;