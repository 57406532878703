import axios from "axios";
var graph = require("@microsoft/microsoft-graph-client");
async function getAuthenticatedClient(accessToken) {
    const client = await graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken);
        },
    });

    return client;
}
export const getAccessToken = (authContext) => {
    return new Promise((resolve, reject) => {
        authContext.acquireToken(
            "https://graph.microsoft.com",
            function (errDesc, token, err, tokenType) {
                if (token) {
                    resolve(token);
                }
            }
        );
    });
};
export const getQueues = (tenantId, scope, userId) => {
    return new Promise(async (resolve, reject) => {
        let url =
            "https://provisioning.heedify.io/QueueManagement/" +
            tenantId +
            "/" +
            scope +
            "/get/ALL?userid=" +
            userId;
        await axios.post(url).then((res) => {
            resolve(res.data);
        });
    });
};
export const getCallFlows = (tenantId, scope, userId) => {
    return new Promise(async (resolve, reject) => {
        let allCallFlowManagementUrl =
            "https://provisioning.heedify.io/CallFlowManagement/" +
            tenantId +
            "/" +
            scope +
            "/get/ALL?userid=" +
            userId
        axios.post(allCallFlowManagementUrl).then((res) => {
            resolve(res.data)
        });
    });
};
export const getAgentGroups = (tenant, scope, agentId) => {
    return new Promise((resolve, reject) => {
        let url = "https://provisioning.heedify.io/GroupManagement/" +
            tenant +
            "/" +
            scope +
            "/get/ALL?userid=" +
            agentId
        axios
            .post(url)
            .then((Groups) => {
                resolve(Groups.data)
            })
            .catch(() => {
            });
    });
};
export const getMyScops = (tenantId, Token) => {
    return new Promise(async (resolve, reject) => {
        const url =
            "https://provisioning.heedify.io/ScopeManagement/" + tenantId + "/get/ALL?token=" + Token
        axios.get(url).then(async (scopes) => {
            resolve(scopes.data)
        })
    })

}
export function getPersonPhoto(authContext, PersonId) {
    return new Promise((resolve, reject) => {
        getAccessToken(authContext).then((accessToken) => {
            getAuthenticatedClient(accessToken).then((client) => {
                var photoUrl =
                    "https://graph.microsoft.com/v1.0/users/" + PersonId + "/photo/$value";
                try {
                    client.api(photoUrl).get().then((Avatar) => {
                        resolve(Avatar)
                    });

                } catch (e) {
                    resolve(null)
                }
            });

        });
    })
}
export async function getUserDetails(authContext, userId) {
    return new Promise((resolve, reject) => {
        getAccessToken(authContext).then((accessToken) => {
            getAuthenticatedClient(accessToken).then((client) => {
                try {
                    client.api("/users/" + userId).get().then((user) => {
                        resolve(user)
                    });
                } catch (e) {
                    resolve(null)
                }
            })
        });
    })
}