import { LOCALES } from "../locales";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.FRENCH]: {
    AVRAGE_WAIT_TIME: "Temps d'attente moyen",
    QUEUED_CALLS: "Appels en attente",
    EXPECTED_WAIT_TIME: "Sonnerie agent en cours",
    QUEUE: "File d'attente",
    HEADER_TITLE: "Heedify Real Time Dashboard",
    Neutral: "Neutre",
    AGENTS: 'Agents',
    DIRECT: "Direct",
    TODAY_CALLS: "Appels d'aujourd'hui",
    MISSED_CALLS: "Appels manqués",
    CONNECTED_CALLS: "Appels connectés",
    TASKS_COMPLETION_RATE: "Tâches complétés",
    CALL_DURATION: "Durée des appels",
    CHAT: "Messages"
  },
};
