import { connect } from 'react-redux';
import Dashboard from '../components/dashboard';
import { setQueuesData, setQueuesFromApi } from '../store/action';
import { authContext } from "../auth";
authContext.handleWindowCallback();
const mapStateToProps = state => {
  return {
    queuesFromSignalRToShow: state?.MessageOfQueus,
    queuesFromApiToShow: state?.QueueDataFromApi
  };
};

const mapDispatchToProps = (dispatch) => ({
  setQueuesData: (res) =>
    dispatch(setQueuesData(res)),
  setQueuesFromApi: (res) =>
    dispatch(setQueuesFromApi(res)),
});




// export de la component de containaire pour q'on peux lui faire un appel dans le Component parent (App)
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);



/*
*/