import React, { useState, useEffect } from "react";
import { getPersonPhoto } from './Services'
import { authContext } from "../auth";
import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";
import translate from "../i18n/translate";
import axios from "axios";
import { getAccessToken } from './Services';
import AgentListHeader from './AgentListHeader'
const AgentPerfermanceComponent = (props) => {
    const [complitedTasks, setComplitedTask] = useState(0);
    const [totalTasks, setTotalTasks] = useState(0);
    const [completedTaskRate, setCompletedTaskRate] = useState(0);
    const [agentPerfermanceParams, setAgentPerfermanceParams] = useState({
        totalCalls: 0,
        missedCalls: 0,
        connectedCalls: 0,
        callsDuration: 0
    })
    const [agentPhoto, setAgentPhoto] = useState()
    const [signalRConnetion, setSignalRConnetion] = useState(props.signalRConnexion)
    const Size = PersonaSize.size28;
    const personaStyles = { root: { margin: "3px 0 3px 0" } };
    const AGENT_PERFERMANCE_WEBSERVICE_PATH = "https://provisioning.heedify.io/AgentPerformance/"
    const AGENT_CONNECTED_CALLS_WEBSERVICE_PATH = "https://provisioning.heedify.io/GetAgentConnectedCalls/";
    const secondsToHHMMSS = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    const setCallsInfo = () => {
        getAccessToken(authContext).then((token) => {
            var now = new Date();
            var timezoneOffset = now.getTimezoneOffset();
            var hours = Math.floor(Math.abs(timezoneOffset) / 60);
            let url = AGENT_PERFERMANCE_WEBSERVICE_PATH + authContext._user.profile.tid + "/default/" + props.agentDetail.id + "?tz=" + hours + "&tasks=true"
            axios.post(url).then((result) => {
                if (result && result.data && result.data !== '') {
                    if (props.agentMonitorMode && props.agentMonitorMode === "SUPERVISE_PSTN_ONLY" && result.data.PSTN) {
                        setComplitedTask(result.data.completedTasks);
                        setTotalTasks(result.data.totalTasks);
                        setAgentPerfermanceParams(agentPerfermanceParams => ({
                            ...agentPerfermanceParams,
                            totalCalls: result.data.PSTN.totalCalls,
                            missedCalls: result.data.PSTN.missedCalls,
                            callsDuration: result.data.PSTN.totalCallsDuration
                        }));
                    } else {
                        setComplitedTask(result.data.completedTasks);
                        setTotalTasks(result.data.totalTasks);
                        setAgentPerfermanceParams(agentPerfermanceParams => ({
                            ...agentPerfermanceParams,
                            totalCalls: result.data.totalCalls,
                            missedCalls: result.data.missedCalls,
                            callsDuration: result.data.totalCallsDuration ? result.data.totalCallsDuration : agentPerfermanceParams.callsDuration,
                        }));
                    }
                }
            })
        })
    }
    useEffect(() => {
        if (props.agentDetail && props.agentMonitorMode && signalRConnetion && signalRConnetion !== null) {
            getAccessToken(authContext).then((token) => {
                var now = new Date();
                var timezoneOffset = now.getTimezoneOffset();
                var hours = Math.floor(Math.abs(timezoneOffset) / 60);
                let url = AGENT_PERFERMANCE_WEBSERVICE_PATH + authContext._user.profile.tid + "/default/" + props.agentDetail.id + "?tz=" + hours + "&tasks=true"
                axios.post(url).then((result) => {
                    if (result && result.data && result.data !== '') {
                        if (props.agentMonitorMode && props.agentMonitorMode === "SUPERVISE_PSTN_ONLY" && result.data.PSTN) {
                            setComplitedTask(result.data.completedTasks);
                            setTotalTasks(result.data.totalTasks);
                            setAgentPerfermanceParams(agentPerfermanceParams => ({
                                ...agentPerfermanceParams,
                                totalCalls: result.data.PSTN.totalCalls,
                                missedCalls: result.data.PSTN.missedCalls,
                                callsDuration: result.data.PSTN.totalCallsDuration
                            }));
                        } else {
                            setTotalTasks(result.data.totalTasks);
                            setAgentPerfermanceParams(agentPerfermanceParams => ({
                                ...agentPerfermanceParams,
                                totalCalls: result.data.totalCalls,
                                missedCalls: result.data.missedCalls,
                                callsDuration: result.data.totalCallsDuration ? result.data.totalCallsDuration : 0,
                            }));
                            setComplitedTask(result.data.completedTasks);
                        }
                    }
                }).then(() => {
                    const onNotifReceivedAgentPerformance = async (result) => {

                        let recivedData = {};
                        if (props.agentMonitorMode && props.agentMonitorMode === "SUPERVISE_PSTN_ONLY" && result.call && result.call.PSTN) {
                            recivedData = result.call.PSTN
                        } else {
                            recivedData = result.call
                        }
                        if (recivedData && recivedData.duration && recivedData.duration !== null && recivedData.duration !== "null") {
                            setAgentPerfermanceParams(agentPerfermanceParams => ({
                                ...agentPerfermanceParams,
                                callsDuration: agentPerfermanceParams.callsDuration + recivedData.duration
                            }));
                        }
                        if (recivedData && recivedData.isMissed && recivedData.isMissed === true) {
                            setAgentPerfermanceParams(agentPerfermanceParams => ({
                                ...agentPerfermanceParams,
                                missedCalls: agentPerfermanceParams.missedCalls + 1,
                                totalCalls: agentPerfermanceParams.totalCalls + 1
                            }));
                        }
                        if (recivedData && recivedData.isMissed === false) {
                            setAgentPerfermanceParams(agentPerfermanceParams => ({
                                ...agentPerfermanceParams,
                                totalCalls: agentPerfermanceParams.totalCalls + 1
                            }));
                        }
                        if (result.task && result.task.complited) {
                            setComplitedTask((complitedTasks) => complitedTasks + result.task.complited)
                        }
                    };
                    props.signalRConnexion.on("agentPerformance" + props.agentDetail.id, onNotifReceivedAgentPerformance);

                })
                let url1 = AGENT_CONNECTED_CALLS_WEBSERVICE_PATH + authContext._user.profile.tid + "/" + props.agentDetail.id + "?token=" + token
                axios.post(url1).then((result) => {
                    if (result && result.data && result.data !== '' && result.data.connectedCalls && Object.keys(result.data.connectedCalls).length) {
                        setAgentPerfermanceParams(agentPerfermanceParams => ({
                            ...agentPerfermanceParams,
                            connectedCalls: Object.keys(result.data.connectedCalls).length
                        }));
                    }
                }).then(() => {
                    const onNotifReceived = async (result) => {
                        if (result && result.connectedCalls) {
                            setAgentPerfermanceParams(agentPerfermanceParams => ({
                                ...agentPerfermanceParams,
                                connectedCalls: Object.keys(result.connectedCalls).length
                            }));
                        }
                    };
                    props.signalRConnexion.on("agentCalls" + props.agentDetail.id, onNotifReceived);

                })
            })
        }
    }, [props.agentDetail, props.agentMonitorModes])
    useEffect(() => {
        getPersonPhoto(authContext, props.agentDetail.id).then((photo) => {
            setAgentPhoto(URL.createObjectURL(photo))
        })
        setInterval(() => {
            setCallsInfo()
        }, 900000);
    }, [])
    useEffect(() => {
        if (totalTasks > 0 && complitedTasks <= totalTasks && complitedTasks >= 0) {
            setCompletedTaskRate(Math.floor((complitedTasks / totalTasks) * 100));
        }
    }, [complitedTasks, totalTasks])
    return (
        <>
            <div className="col-6" style={props.index === 0 || props.index === 1 ? { minHeight: '75px' } : {}}>
                {
                    props.index === 0 || props.index === 1 ?
                        <AgentListHeader />
                        :
                        null
                }
                <div className="agent-mean-div" key={props.agentDetail.id}>
                    <div className="agent-person-div">
                        {
                            agentPhoto ?
                                <Persona
                                    {...{
                                        imageUrl: agentPhoto,
                                        text: props.agentDetail.displayName,
                                        showSecondaryText: false,
                                        // imageShouldStartVisible: true,
                                        imageShouldFadeIn: true,
                                    }}
                                    size={Size}
                                    styles={personaStyles}
                                />
                                :
                                <Persona
                                    {...{
                                        text: props.agentDetail.displayName,
                                        showSecondaryText: false,
                                        // imageShouldStartVisible: true,
                                        imageShouldFadeIn: true,
                                    }}
                                    size={Size}
                                    styles={personaStyles}
                                />
                        }
                    </div>
                    <div className="agent-perfermance-item" ><div className="agent-perfermance-item-value">{agentPerfermanceParams.totalCalls}</div></div>
                    <div className="agent-perfermance-item" ><div className="agent-perfermance-item-value">{agentPerfermanceParams.missedCalls}</div></div>
                    <div className="agent-perfermance-item" ><div className="agent-perfermance-item-value">{secondsToHHMMSS(agentPerfermanceParams.callsDuration)}</div></div>
                    <div className="agent-perfermance-item" ><div className="agent-perfermance-item-value">{agentPerfermanceParams.connectedCalls}</div></div>
                    <div className="agent-perfermance-item" ><div className="agent-perfermance-item-value">{completedTaskRate > 100 || completedTaskRate < 0 ? '--' : completedTaskRate}  %</div></div>
                </div>
            </div>
        </>

    )
}
export default AgentPerfermanceComponent